import React from "react";
import ModalBasic from "../../component/ModalBasic";

import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";
import CKClassicEditor from "../../../components/ckEditor/CKClassicEditor";

const SendRejectionReason = (props) => {
    return (
        <ModalBasic
            id="send-payment-link-modal"
            modalOpen={props.rejectionModal}
            setModalOpen={props.setOpenModal}
            title="Send Rejection Reason Detail"
        >
            <div className="pb-6 px-7 mt-2">
                <div>
                    <CKClassicEditor
                        key={"sendRejectionReason"}
                        onChange={(e) => props.onTinyEceEditorChange(e, "sendRejectionReason")}
                        data={props.sendRejectionReason}                    
                    />
                   
                   
                </div>
                <div className="text-center">
                    {
                        props && props.isLoadingRejectAplication === true ?
                            <ButtonLoader className="btn btn-orange mt-8" />
                            :
                            <button
                                className={`btn ${props.btn_color ? props.btn_color : 'btn-blue'} text-base text-[#fff] font-bold mt-8`}
                                onClick={() => props.rejectCONQUASMemberApplication('Rejected')}
                            >
                                OK
                            </button>
                    }
                </div>
            </div >
        </ModalBasic>
    );
}
export default SendRejectionReason;