import moment from "moment";
import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import DropdownSelect from "../../components/dropdown/Dropdown";
import DropdownAction from "../../components/dropdown/DropdownAction";
import Table from "../../components/table/Table";
import ActionContainer from "../../components/UIComponent/ActionTableComponent/actionContainer";
import Checkbox from "../../components/UIComponent/Checkbox";
import FinanceServices from "../../services/axiosServices/apiServices/FinanceServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { getAuthProps } from "../../utils/AuthenticationLibrary";
import { isNullString } from "../../utils/Utils";
import { saveAs } from "file-saver";
import { encryptAES } from "../../utils/Encryption";
import ModalBasic from "../component/FormModal";
import CommonServices from "../../services/axiosServices/apiServices/CommonServices";
import ValidationText from '../../utils/validation/ValidationText';
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import { CommonValidationMessages } from "../../utils/Messages";
export default class CreditNoteApproval extends Component {
  constructor(props) {
    super(props);
    this.financeService = new FinanceServices();
    this.commonServices = new CommonServices();
    this.swalServices = new SwalServices();
    this.state = {
      creditNoteStatusList: [],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        status: 0,
        creditNoteStatusId: 0,
        creditNoteNumber: "",
        approvalEmailFilter: "",
      },
      actionDropdownApproved: ["View Credit Note"],
      actionDropdownForSent: ["Closed"],
      route: "",
      creditNoteList: [],
      totalResults: 0,
      selectedIdList: [],
      checkedRow: false,
      checkedHeader: false,
      isRejected: true,
      isApprove: true,
      isLoading: false,
      isApproveCreditNote: false,
      filePath: "",
      comments: "",
      fileDisplayName: "",
      validationRule: {
        comments: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "comments"
            ),
          },
          // {
          //   type: 'decimalNumber',
          //   message: CommonValidationMessages.FieldRequired.replace('{0}', 'only numbers')
          // },
        ],
        filePath: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "filePath"
            ),
          },
        ],
      },
      validState: {
        isValid: true,
        error: {},
      },
    };


  }




  validateFieldOutstandingDetails = (key) => {
    debugger
    let details = {
      comments: this.state.comments,
      filePath: this.state.filePath
    }

    const newValidState = validate(
      key,
      details,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  issAllvalidateFieldOutstandingDetails = () => {
    let details = {
      comments: this.state.comments,
      filePath: this.state.filePath
    }
    const newValidState = isValidForm(
      details,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };


  getAllFinanceCreditNoteList = () => {
    this.setState({ isLoading: true });
    this.financeService.getAllFinanceCreditNoteList(this.state.pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent !== null) {
          let data = response.responseItem.responseContent.itemList;
          data.map((x) => {
            x.creditNoteMonth = moment(x.creditNoteMonth).format("DD MMM YYYY");
           //x.approvalDate = moment(x.approvalDate).format("DD MMM YYYY");
            x.isSelected = false;
            x.approvalDate = x.approvalDate === 'Invalid date' || x.approvalDate === null ? '-' : moment(x.approvalDate).format("DD MMM YYYY");
            return x;
          });
          this.setState({ creditNoteList: data, totalResults: response.responseItem.responseContent.totalCount });
        }

      } else {
        this.swalServices.Error("Something went wrong.");
      }
      this.setState({ isLoading: false });
    })
  }

  getFinanceCreditNoteStatusList = () => {
    this.financeService.getFinanceCreditNoteStatusList().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ creditNoteStatusList: response.responseItem.responseContent }, () => { this.getAllFinanceCreditNoteList() });
      } else {
        this.getAllFinanceCreditNoteList()
        this.swalServices.Error("Something went wrong.");
      }
    })
  }

  onFileChange = (event) => {

    const file = event.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      let request = {};
      request.storagePath = "CreditNoteImages";
      request.file = file;
      request.fileName = file.name;

      this.commonServices.uploadPDF(request).then((response) => {
        if (response != null) {
          if (request.storagePath === "CreditNoteImages") {
            let detail = { ...this.state.singleOutstandingInvoiceDetails };
            detail["filePath"] = response.fileName;
            detail["fileDisplayName"] = file.name;

            this.setState({ filePath: response.fileName, fileDisplayName: file.name }, () => {
              this.swalServices.Success("Image File Uploaded");
            });
          }
        } else {
          this.swalServices.Error(response.message);
        }
      });
    } else {
      this.swalServices.Error("Please select only Image file");
    }
  };

  approveCreditNote = () => {
    this.setState({ isApproveCreditNote: true });
  }

  financeApproveRejectCreditNoteApproval = (identity) => {
     
    let issAllvalidateFieldOutstandingDetails = this.issAllvalidateFieldOutstandingDetails()
    if (issAllvalidateFieldOutstandingDetails) {
      let isCheckForApproval = true;

      let request = {

        updatedBy: 1,
        updatedAt: moment().format("yyyy-MM-DD"),
        updatedAppId: 114,
        financeCreditNoteId: [],
        creditNoteImage: "",
        comments: ""
      };
      if (identity === 'Reject') {
        request.creditNoteStatusId = 6;
      } else if (identity === 'Approve') {

        request.creditNoteStatusId = 2;
        request.creditNoteImage = this.state.filePath;
        request.comments = this.state.comments;
      }
      var selectedIdList = this.state.creditNoteList.filter(
        (get) => get.isSelected === true
      );
      selectedIdList = selectedIdList.map((p) => (p.financeCreditNoteId));
      request.financeCreditNoteId = [...selectedIdList];
      let cookie = getAuthProps();
      const adminUserName = (cookie && cookie.userName) ? cookie.userName : '';
      if (adminUserName) {
        this.state.creditNoteList.map((x) => {
          if (x.isSelected === true) {
            if (x.approvalEmail !== adminUserName) {
              isCheckForApproval = false;
            }
          }
          return x;
        });
      }
      if (isCheckForApproval) {
         
        const adminUserId = (cookie && cookie.adminUserId) ? cookie.adminUserId : '';
        request.updatedBy = adminUserId;
        this.financeService.financeApproveRejectCreditNoteApproval(request).then((response) => {
           
          if (response.statusCode === 200 && response.responseItem != null) {
            this.setState({ isRejected: true, isApprove: true, isApproveCreditNote: false ,comments:'',filePath:null}, () => {
              this.getAllFinanceCreditNoteList()
              // this.addMembershipCreditNoteApprovalRecordsForBatchJob()
            });
            //this.getAllFinanceCreditNoteList();
          } else {
            this.swalServices.Error("Something went wrong.");
          }
        })
      } else {
        let creditNoteNos = '';
        this.state.creditNoteList.map((x) => {
          if (x.isSelected === true) {
            if (x.approvalEmail !== adminUserName) {
              let email = x.approvalEmail;
              creditNoteNos = creditNoteNos.concat(`${email}, `);
            }
          }
          return x;
        });
        this.swalServices.Error(`you are not authorized to approve or reject these credit notes ${creditNoteNos}.`);
      }
      this.getAllFinanceCreditNoteList();
    }
  }

  financeUpdateStatusForCreditNote = (value) => {
    let request = {
      financeCreditNoteId: value,
      creditNoteStatusId: 5,
    };
    this.financeService.financeUpdateStatusForCreditNote(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.swalServices.Success("Credit note status updated.");
        this.getAllFinanceCreditNoteList();
      } else {
        this.swalServices.Error("Something went wrong.");
      }
    })
  }
  financeGetCreditNoteInvoice = (value) => {
    let request = [];
    request.push(value);
    if (isNullString(value) !== "") {
      this.financeService.financeGetCreditNoteInvoice(request).then((response) => {
        if (response.data !== null && response.status === 200) {
          var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss")

          var filename = "";
          filename = `Credit-Note-${formattedDate}`;

          var file = new Blob([response.data], { type: "application/pdf" });

          URL.createObjectURL(file);
          window.open(URL.createObjectURL(file), "_blank");

          saveAs(file, filename + ".pdf");
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
    else {
      this.swalServices.Error("No Invoice is Available");
    }
  }

  addMembershipCreditNoteApprovalRecordsForBatchJob = (financeCreditNoteId) => {
    let request = [financeCreditNoteId];
    this.financeService.addMembershipCreditNoteApprovalRecordsForBatchJob(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem.responseContent != null) {
        //let cancelReason = response.responseItem.responseContent;
        // this.setState({ cancellationReason: cancelReason });
      }
      else {
        this.swalServices.Error(response.message);
      }
    });
  }

  componentDidMount() {
    this.getFinanceCreditNoteStatusList();
  }

  handleChange = (event) => {
    let { name, value } = event.target;
    let detail = { ...this.state.pagination };
    detail[name] = value;
    this.setState({ pagination: detail });
  }

  handleChangeInDropdown = (id, drpIdentity) => {
    let detail = { ...this.state.pagination };
    detail.creditNoteStatusId = id;
    this.setState({ pagination: detail });
  }

  setPagination = (newPagination) => {

    this.setState({ pagination: newPagination }, () => { this.getAllFinanceCreditNoteList() });
  };

  actionClick = (index, value, option, event) => {
    if (option === "View Credit Note" || option === "Processing") {
      if (value && value > 0) {
        this.setState({
          route: `/financial/CreditNoteManagement/CreditNoteId?=${encryptAES(value)}`,
        });
      }
    }
    if (option === "Reject") {
      this.financeRejectCreditNoteApproval(value);
    }
    if (option === "Closed") {
      this.financeUpdateStatusForCreditNote(value);
    }
  };

  actions = (element, index, value) => {
    return element !== "financeCreditNoteId" ? null : (
      <div className="relative w-full credit-note-action-dropdown">
        <ActionContainer>
          <>
            {(this.state.creditNoteList.find(x => x.financeCreditNoteId === value)).financeCreditNoteStatus === 'Pending Approval' ?
              // <DropdownAction
              //   disabled={true}
              // />
              <DropdownAction
                key={index}
                value={value}
                id={index}
                index={index}
                options={this.state.actionDropdownApproved}
                align="right"
                onOptionClick={this.actionClick.bind(this)}
              />
              :
              (<>
                {(this.state.creditNoteList.find(x => x.financeCreditNoteId === value)).financeCreditNoteStatus === 'Sent' ?
                  <DropdownAction
                    key={index}
                    value={value}
                    id={index}
                    index={index}
                    options={this.state.actionDropdownForSent}
                    align="right"
                    onOptionClick={this.actionClick.bind(this)}
                  />
                  :
                  (<>
                    {(this.state.creditNoteList.find(x => x.financeCreditNoteId === value)).financeCreditNoteStatus === 'Rejected' ?
                      <DropdownAction
                        disabled={true}
                      />
                      :
                      (<>
                        <DropdownAction
                          key={index}
                          value={value}
                          id={index}
                          index={index}
                          options={this.state.actionDropdownApproved}
                          align="right"
                          onOptionClick={this.actionClick.bind(this)}
                        />
                      </>)
                    }
                    {/* <DropdownAction
                      key={index}
                      value={value}
                      id={index}
                      index={index}
                      options={this.state.actionDropdownApproved}
                      align="right"
                      onOptionClick={this.actionClick.bind(this)}
                    /> */}
                  </>)
                }
              </>)
            }
          </>
        </ActionContainer>
      </div>
    );
  };

  select = (element, index, value) => {
    return element !== "selectRow" ? null : (
      <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#000000]">
        <input
          name="isRequireQuotation"
          type="checkbox"
          className="cursor-pointer h-6 w-6 rounded check-box-custom"
          onChange={() => this.selectSingleRows(value)}
          checked={this.state.creditNoteList[index].isSelected === true ? true : false}
        />
      </td>
    );
  };

  selectSingleRows = (value) => {
    let getAllRows = [...this.state.creditNoteList];
    let check = getAllRows.find(x => x.financeCreditNoteId === value)
    let checkedHeader = true;
    if (check.isSelected) {
      getAllRows.map((x) => {
        if (x.financeCreditNoteId === value) {
          x.isSelected = false;
        }
        return x;
      })

      this.setState({ isRejected: true, isApprove: true });

    } else {
      getAllRows.map((x) => {
        if (x.financeCreditNoteId === value) {
          x.isSelected = true;
        }
        return x;
      })
    }
    let isCheck = false;
    getAllRows.map((x) => {
      if (x.isSelected === true) {
        if (x.financeCreditNoteStatus === 'Approved' || x.financeCreditNoteStatus === 'Rejected' || x.financeCreditNoteStatus === 'Closed' || x.financeCreditNoteStatus === 'Sent') {
          isCheck = true;
        } else {
          this.setState({ isRejected: false, isApprove: false });
        }
      } else {
        checkedHeader = false;
      }
      return x;
    })

    if (isCheck) {
      this.setState({ isRejected: isCheck, isApprove: isCheck });
    }
    this.setState({ creditNoteList: getAllRows, checkedHeader: checkedHeader });
  }

  selectAllRows = () => {
    let getAllRows = [...this.state.creditNoteList];
    let isAnyApproveReject = 0;
    if (this.state.checkedHeader) {
      getAllRows.map((item) => {
        item.isSelected = false;
        return item;
      });
      this.setState({ creditNoteList: getAllRows, checkedHeader: false });
    } else {
      getAllRows.map((item) => {
        item.isSelected = true;
        if (item.financeCreditNoteStatus === 'Approved' || item.financeCreditNoteStatus === 'Rejected') {
          isAnyApproveReject += 1;
        }
        return item;
      });
      if (isAnyApproveReject > 0) {
        this.setState({ isRejected: true, isApprove: true });
      } else {
        this.setState({ isRejected: false, isApprove: false });
      }
      this.setState({ creditNoteList: getAllRows, checkedHeader: true });
    }
  }

  render() {
    if (this.state.route !== "") {
      return <Navigate to={this.state.route} />;
    }
    return (
      <div className="main-body-section finance-body-sec">
        <div className="bg-white pb-2 mt-10">
          <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pt-10">
            <div className=" 2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
              <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                Status
              </h2>
              <DropdownSelect
                drpIdentity="Status"
                optionArray={this.state.creditNoteStatusList}
                setFilterParameters={this.handleChangeInDropdown.bind(this)}
                value={this.state.pagination.creditNoteStatusId}
              />
            </div>
            <div className=" 2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
              <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                Credit Note Number
              </h2>
              <input
                id="default"
                name="creditNoteNumber"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                value={this.state.pagination.creditNoteNumber}
                onChange={(e) => this.handleChange(e)}
              />
            </div>
            <div className=" 2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
              <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                Search
              </h2>
              <input
                id="default"
                name="searchText"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                value={this.state.pagination.searchText}
                onChange={(e) => this.handleChange(e)}
              />
            </div>
            <div className=" 2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
              <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                Approval Email
              </h2>
              <input
                id="default"
                name="approvalEmailFilter"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                value={this.state.pagination.approvalEmailFilter}
                onChange={(e) => this.handleChange(e)}
              />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full items-end px-8 pb-12">
            <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
              <div className="mt-8 flex items-center justify-end w-full">
                <button className="btn btn-blue text-xl border text-white "
                  onClick={() => { this.getAllFinanceCreditNoteList() }}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Table Section Start */}
        <div className="mt-10 credit-note-approval-table">
          <Table
            columns={[
              { name: "selectRow", title: <Checkbox handleCheckbox={() => this.selectAllRows()} checkedHeader={this.state.checkedHeader} /> },
              { name: "creditNoteNumber", title: "Credit Note Number" },
              { name: "name", title: "Created By" },
              { name: "approvalDate", title: "Approval Date" },
              { name: "invoiceNum", title: "Invoice Number" },
              { name: "companyName", title: "Company Name" },
              { name: "contactPerson", title: "Contact Person" },
              { name: "creditNoteTotalRefund", title: "Credit Amount" },
              { name: "creditNoteMonth", title: "Created Date" },
              { name: "creditNoteOpenCreditAmout", title: "Open Credit" },
              { name: "financeCreditNoteStatus", title: "Status" },
              // { name: "approvalEmail", title: "Approval Email" },
              { name: "financeCreditNoteId", title: "Actions" },
            ]}
            rows={this.state.creditNoteList}
            isLoading={this.state.isLoading}
            sortingColumns={["status"]}
            pagination={this.state.pagination}
            totalResultes={this.state.totalResults}
            totalCount={this.state.totalResults}
            setPagination={this.setPagination.bind(this)}
            customScope={[
              {
                column: "financeCreditNoteId",
                renderTableData: this.actions.bind(this),
              },
              {
                column: "selectRow",
                renderTableData: this.select.bind(this),
                valueColumnName: "financeCreditNoteId",
              },
            ]}
            isRequiredButton1={true}
            buttonText1="Reject"
            button1Click={() => { this.financeApproveRejectCreditNoteApproval("Reject") }}
            button1Disable={this.state.isRejected}
            isRequiredButton2={true}
            buttonText2="Approve"
            // button2Click={() => { this.financeApproveRejectCreditNoteApproval("Approve") }}
            button2Click={() => { this.approveCreditNote() }}
            button2Disable={this.state.isApprove}
          />
        </div>
        {/*Table Section End */}

        <div className="Approve-Credit-Note-modal">
          <ModalBasic
            id="ApproveCreditNote"
            modalOpen={this.state.isApproveCreditNote}
            setModalOpen={() => this.setState({ isApproveCreditNote: false })}
            title="Approve Credit Note"
          >
            <div className="grid grid-span-12 gap-6 p-5 pb-10">
              <div className="col-span-12 mb-5">
                <div className="items-center mb-5">
                  <label className="text-[#000000] text-[16px] mobile-14 md:mb-5 inline-block">
                    File 1
                  </label>
                  <div className="flex items-center disabled-input">
                    <input
                      className="w-full custom-input"
                      type="text"
                      name=""
                      value={this.state.fileDisplayName}
                      disabled
                    />
                    <div>
                      <ValidationText
                        error={this.state.validState.error.fileDisplayName}
                      />
                    </div>
                    <div className="flex items-center justify-center ml-4 ">
                      <form className="custom-uploader custom-file-upload-btn flex justify-center items-center text-center btn btn-blue-border">
                        <label
                          htmlFor={`upload_File_For_CreditNote`}
                          className="text-uplod block text-sm text-[#005B9C] w-full whitespace-nowrap"
                        >
                          Select File
                        </label>
                        {/* <img
                          className="uplod-svg inline-flex w-5 h-5 fill-[#000000] ml-4"
                          src="/pdf-upload.svg"
                        ></img> */}
                        <input
                          className="sr-only"
                          //  key={`FileQuestion${key}`}
                          id={`upload_File_For_CreditNote`}
                          type="file"
                          onChange={(event) => this.onFileChange(event)}
                        />
                      </form>
                    </div>
                  </div>
                </div>
                <div className="items-center">
                  <label className="text-[#000000] text-[16px] mobile-14 md:mb-5 inline-block">
                    Comments
                    <span className="text-[#c00000]">*</span>
                  </label>
                  <textarea
                    className="w-full"
                    placeholder="Comments"
                    id=""
                    rows="5"
                    name="comments"
                    value={this.state.comments}
                    onChange={(e) => { this.setState({ comments: e.target.value }) }}
                    onBlur={() => this.validateFieldOutstandingDetails("comments")}
                  />
                  <div>
                    <ValidationText
                      error={this.state.validState.error.comments}
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-12 flex justify-center">
                <button class="w-32 px-7 py-2 btn-sm btn-blue text-lg text-white"
                  onClick={() => {
                    // this.addProofInPaymentProofMapping();
                    this.financeApproveRejectCreditNoteApproval("Approve")
                  }}
                >Approve</button>
              </div>
            </div>

          </ModalBasic>
        </div>
      </div>
    );
  }
}
